import { SET } from './constants';

export default function reducer(state = null, action) {
  switch (action.type) {
    case SET:
      return action.data || null;

    default:
      return state;
  }
}
