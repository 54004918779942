import { combineReducers } from 'redux';
import bounds from './bounds/reducer';
import trafficOverlays from './trafficOverlays/reducer';
import tiles from './tiles/reducer';

export default combineReducers({
  bounds,
  trafficOverlays,
  tiles,
});
