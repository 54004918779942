export function getTabs(state) {
  return state.tabs;
}

export function getSelectedTabs(state) {
  return state.tabs[state.selectedTab];
}

export function getTabIndexById(state, tabId) {
  let indexRes;
  getTabs(state).find((tab, index) => {
    if (tab.id === tabId) {
      indexRes = index;
      return true;
    }
    return false;
  });
  return indexRes;
}
